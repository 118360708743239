<template>
  <div class="report">
    <div class="content">
      <div class="report__caption">Правовая информация</div>
    </div>
    <Breadcrumbs :pages="[{ name: 'Правовая информация' }]" />
    <div class="content">
      <div class="report__title">Заявление об ограничении ответственности</div>
      <div class="report__description">
        Мы не несём ответственности за любые действия, предпринятые каким-либо
        лицом или организацией, вне зависимости от их местоположения, в
        результате прямого или косвенного ознакомления с информацией,
        содержащейся на данном сайте, не принимая во внимание, была ли данная
        информация представлена нами или каким-либо иным лицом. Ничто на данном
        веб-сайте не должно истолковываться как предоставление совета или
        рекомендации, а данный веб-сайт не должен служить основанием для
        принятия каких-либо решений или осуществления каких-либо действий.
        <br />
        <br />
        В связи с непрерывным развитием медицины информация на данном веб-сайте
        может быть не всегда актуальной, и по этой причине такая информация
        публикуется по принципу «КАК ЕСТЬ» и «ПО МЕРЕ ПОСТУПЛЕНИЯ». МЫ НЕ ДАЕМ
        НИКАКИХ ГАРАНТИЙ И ЗАВЕРЕНИЙ, А ТАКЖЕ НЕ БЕРЕМ НА СЕБЯ ОБЯЗАТЕЛЬСТВ,
        ЯВНО ВЫРАЖЕННЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ, В ОТНОШЕНИИ СОДЕРЖАНИЯ ДАННОГО
        ВЕБ-САЙТА (ВКЛЮЧАЯ СВОЕВРЕМЕННОСТЬ ПРЕДСТАВЛЕНИЯ ИНФОРМАЦИИ, ЕЁ
        СООТВЕТСТВИЕ ДЕЙСТВИТЕЛЬНОСТИ, ТОЧНОСТЬ, ПОЛНОТУ ИЛИ ПРИГОДНОСТЬ ДЛЯ
        ИСПОЛЬЗОВАНИЯ ПО НАЗНАЧЕНИЮ, А ТАКЖЕ ТО, ЧТО СВЕДЕНИЯ, ПОЛУЧЕННЫЕ В
        РЕЗУЛЬТАТЕ ИСПОЛЬЗОВАНИЯ ДАННОГО ВЕБ-САЙТА, МОГУТ БЫТЬ БЕЗОШИБОЧНЫМИ ИЛИ
        ДОСТОВЕРНЫМИ) Использование информации, полученной или скачанной с
        данного сайта, происходит по вашему собственному усмотрению и
        представляет ваш собственный риск. Некоторые юрисдикции не допускают
        исключения подразумеваемых гарантий, в этом случае вышеупомянутое
        условие не будет там применяться.
        <br />
        <br />
        Любая медицинская информация, содержащаяся на данном веб-сайте, не
        предназначена для использования в качестве замены медицинской
        консультации, и мы не можем отвечать на незапрашиваемые нами электронные
        письма, касающиеся вопросов индивидуального медицинского обслуживания.
        Информация о продуктах, упоминаемых на данном веб-сайте, может
        отличаться в разных странах. Пациентам и работникам здравоохранения
        следует сверяться с местными медицинскими ресурсами, а также с
        контролирующими органами с целью получения информации, соответствующей
        данной стране. ВАМ СЛЕДУЕТ ПРОКОНСУЛЬТИРОВАТЬСЯ У СООТВЕТСТВУЮЩЕГО
        КВАЛИФИЦИРОВАННОГО МЕДИЦИНСКОГО РАБОТНИКА ПО ЛЮБОМУ ВОПРОСУ ИЛИ
        ПРОБЛЕМЕ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ИНФОРМАЦИЯ, РАЗМЕЩЕННАЯ НА ДАННОМ
        ВЕБ-САЙТЕ, ПЕРЕД ТЕМ, КАК ПРЕДПРИНИМАТЬ КАКИЕ-ЛИБО ДЕЙСТВИЯ.
        <br />
        <br />
        Ничто на данном веб-сайте не подразумевает приглашения к осуществлению
        инвестиционной деятельности или иным операциям с акциями, депозитарными
        расписками и прочими ценными бумагами компании AstraZeneca PLC.
        Фактические результаты и события могут существенно отличаться от любого
        прогноза, а прошлые курсы ценных бумаг не должны служить основанием для
        расчетов на будущее.
      </div>
      <div class="report__title">Наша ответственность</div>
      <div class="report__description">
        В установленных законом случаях мы, прочие члены нашей Группы (в
        соответствии с приведённым ниже определением), а также третьи лица,
        имеющие к нам отношение, настоящим заявлением в прямой форме исключаем:
        <br />
        <br />
        Все условия, гарантии и прочие положения, которые в других случаях могут
        предусматриваться законом, обычным правом или правом справедливости;
        <br />
        <br />
        Любую ответственность за нанесение пользователю прямого или косвенного
        ущерба или вреда посредством нашего веб-сайта, или в связи с
        использованием либо невозможностью использования нашего веб-сайта и
        любых других веб-сайтов, ссылающихся на наш веб-сайт или на материалы,
        размещенные на нём, включая ответственность за (i) потерю дохода или
        прибыли, (ii) утрату деловых возможностей, (iii) упущенную выгоду или
        незаключённый контракт, (iv) потерю сбережений, (v) потерю данных, (vi)
        потерю неосязаемых активов, (vii) потерянное рабочее время, а также
        (viii) за любой другой ущерб или вред, причиненный в результате
        правонарушения (включая халатность), нарушения договорных условий и
        т.д., даже если это было предсказуемо.
        <br />
        <br />
        Указанное выше не распространяется на нашу ответственность за причинение
        смертельного исхода или нанесение телесных повреждений, возникающих
        вследствие нашей халатности, а также на нашу ответственность за
        намеренное введение в заблуждение или искажение фактов в отношении
        существенного вопроса, и на любую другую ответственность, которая не
        может быть исключена или ограничена в соответствии с действующим
        законодательством.
        <br />
        <br />
        Мы не гарантируем, что данный веб-сайт будет работать бесперебойно и
        безошибочно, что неисправности будут устраняться, или что данный
        веб-сайт или сервер, на котором находится данный сайт, не содержат
        вирусов или ошибок.
        <br />
        <br />
      </div>
      <div class="report__title">Права на интеллектуальную собственность</div>
      <div class="report__description">
        Права на интеллектуальную собственность в отношении данного веб-сайта
        распространяются на все документы, файлы, тексты, изображения,
        RSS-каналы, аудио файлы, подкасты, видео файлы, учебные материалы на
        flash-платформе, графику, устройства, а также код, содержащийся в
        структуре и в общем графическом пользовательском интерфейсе данного
        сайта, и принадлежат компании AstraZeneca UK Limited, её аффилированным
        компаниям (в соответствии с приведённым ниже определением) или сторонним
        провайдерам. Данные элементы защищены законами и договорами об авторском
        праве по всему миру. Все права на них защищены.
        <br />
        <br />
        Вы имеете право сделать единственную копию и распечатывать отрывки или
        целые документы, взятые с данного сайта (за исключением материала,
        принадлежащего третьим лицам, помеченного как таковой), для
        некоммерческого использования при условии, что такая копия или
        распечатанные документы сохраняют за собой все авторские права или иные
        указания на авторскую принадлежность, и что внутри них содержится
        заявление об ограничении ответственности. Все наименования компании
        AstraZeneca, её логотипы и товарные знаки не могут быть использованы или
        воспроизведены без нашего предварительного письменного согласия.
        <br />
        <br />
        Во всех остальных случаях воспроизведение части или всего содержания
        данного сайта в любой форме, включая кадрирование, составительскую
        работу, основанную на данных веб-сайта и на его содержании, размещение
        данных на других веб-сайтах и в системах электронного поиска информации
        или публикация запрещены. Не разрешается использовать диаграммы,
        иллюстрации, фотографии, видео и аудио файлы или графику отдельно от
        сопровождающего их текста. Никакие ссылки на данный веб-сайт не могут
        присутствовать на каком-либо другом сайте без нашего предварительного
        письменного согласия.
        <br />
        <br />
        Мы не можем гарантировать Вам право на использование информации сайта,
        находящейся в распоряжении третьих лиц, и вы должны получить разрешение
        от владельца такой информации перед использованием или скачиванием
        данного материала. Содержание, защищенное авторским правом, не может
        быть изменено, а также никакие сведения об авторстве или авторских
        правах не могут быть изменены без предварительного получения
        соответствующего согласия.
        <br />
        <br />
        За исключением ограниченного разрешения, упомянутого выше, ничто на
        данном веб-сайте не истолковывается как представление какого-либо
        другого права или лицензии.
        <br />
        <br />
        Все наименования продукции, которые упоминаются на сайте, являются
        товарными знаками компании AstraZeneca UK Limited или её аффилированных
        компаний, за исключением тех знаков, на которых помечено, что ими
        владеют другие компании и что все права защищены.
      </div>
      <div class="report__title">
        Права на использование информации, представленной пользователем
      </div>
      <div class="report__description">
        В случае если вы предоставляете какой-либо материал для размещения на
        данном сайте (включая личные данные, ноу-хау, комментарии, идеи,
        вопросы, методы, аннотации и т.д.), вы соглашаетесь, (i) что такой
        материал не будет являться конфиденциальным, (ii) что на данный материал
        не будут распространяться права собственности, и (iii) предоставить нам
        безотзывную, мировую, бессрочную, без уплаты роялти, неисключительную
        лицензию на использование, раскрытие, копирование, изменение, адаптацию,
        публичную демонстрацию и перевод всего содержания данного материала или
        какой-либо его части в любых целях без ограничений. По нашему
        собственному усмотрению мы оставляем за собой право на удаление с сайта
        размещенного вами материала без уведомления. Мы также оставляем за собой
        право на раскрытие ваших личных данных любому третьему лицу в случае
        поступления жалобы с его стороны о том, что размещенный вами на сайте
        материал нарушает право такого лица на интеллектуальную собственность
        или на неприкосновенность частной жизни.
      </div>
      <div class="report__title">
        Распространение вирусов, несанкционированный доступ и прочие
        правонарушения
      </div>
      <div class="report__description">
        Запрещается использовать наш веб-сайт для сознательного распространения
        вирусов, троянов, червей, логических бомб и прочих вредоносных и
        технологически опасных программ. Запрещается предпринимать попытки
        получения несанкционированного доступа к нашему веб-сайту, серверу, на
        котором располагается наш веб-сайт, или к любому другому серверу,
        компьютеру или базе данных, имеющих отношение к нашему веб-сайту.
        Запрещается воздействовать на наш сайт, вызывая отказ в обслуживании
        законных пользователей или распределенную атаку типа отказа в
        обслуживании.
        <br />
        <br />
        Нарушая это положение, вы совершаете уголовное правонарушение в
        соответствии с Законом о неправомерном использовании компьютерных
        технологий от 1990 г. Мы вправе сообщить об этом нарушении
        соответствующим правоохранительным органам и вправе раскрыть им ваши
        личные данные. В случае такого правонарушения вы теряете право на
        использование нашего веб-сайта.
        <br />
        <br />
        Мы не несем ответственности за нанесение ущерба или вреда, причиненного
        в результате совершения распределенной атаки типа отказа в обслуживании,
        заражения вирусами и прочими технологически опасными программами,
        которые могут поразить ваше компьютерное оборудование, программное
        обеспечение, данные или материалы вследствие использования вами нашего
        веб-сайта или скачивания вами любого материала, размещенного на нем или
        на любом другом сайте, ссылку на который вы получили на нашем сайте. В
        соответствии с надлежащей компьютерной практикой советуем вам проверять
        на вирусы все открываемые / скачиваемые материалы с нашего веб-сайта,
        используя доступное для приобретения, обновленное антивирусное
        программное обеспечение.
      </div>
      <div class="report__title">Сторонние веб-сайты и их содержание</div>
      <div class="report__description">
        Данный веб-сайт может содержать информацию, принадлежащую третьим лицам
        (к примеру, статьи, изображения, данные или аннотации), а также может
        содержать гипертекстовые ссылки на сторонние сайты. Мы предоставляем
        нашим пользователям такую информацию, принадлежащую третьим лицам, и
        ссылки бесплатно. Ссылки предоставляются исключительно в информационных
        целях. Мы не контролируем веб-сайты, принадлежащие третьим лицам, а
        также всю информацию, содержащуюся или доступную на таких сайтах, и,
        следовательно, мы не подтверждаем, не спонсируем, не рекомендуем или
        иным образом не берем на себя какую-либо ответственность за такие
        сторонние веб-сайты, за информацию, размещенную на них, или за их
        доступность. В частности, мы не берем на себя ответственности,
        вытекающей из какого-либо заявления, что любая информация, принадлежащая
        третьему лицу (опубликованная на этом или каком-либо другом веб-сайте),
        нарушает права на интеллектуальную собственность какого-либо лица, или
        ответственности, вытекающей из какой-либо информации или заключения,
        содержащихся на стороннем веб-сайте.
      </div>
      <div class="report__title">Изменение веб-сайта</div>
      <div class="report__description">
        Мы оставляем за собой право на изменение любой части данного веб-сайта
        или настоящего официального уведомления в любой момент без
        предупреждения. Любые изменения, вносимые в настоящее официальное
        уведомление, вступают в силу со следующего вашего посещения данного
        веб-сайта. Несмотря на все вышеизложенное, мы не обязаны своевременно
        обновлять данный веб-сайт.
      </div>
      <div class="report__title">
        Маркетинговый кодекс International Federation of Pharmaceutical
        manufacturers and Associations (IFPMA)
      </div>
      <div class="report__description">
        Любая рекламная информация, содержащаяся на данном международном
        веб-сайте, подготовлена со ссылкой на международный маркетинговый кодекс
        IFPMA и маркетинговый кодекс Ассоциации Международных Фармацевтических
        Производителей (Россия). Используя данный веб-сайт, вы соглашаетесь с
        тем, что любые вопросы, которые у вас возникают в отношении данной
        информации, вы сначала разрешаете с нами. После этого, если вас не
        устроил наш ответ, вы можете обратиться в IFPMA, расположенную в Женеве
        (Швейцария) или Ассоциацию Международных Фармацевтических Производителей
        (Россия), расположенную в Москве. Любые жалобы, касающиеся рекламного
        содержания, характерного для одной страны, могут быть поданы в
        Ассоциацию Международных Фармацевтических Производителей этой страны.
      </div>
      <div class="report__title">Юрисдикция и применимое право</div>
      <div class="report__description">
        Английские суды имеют исключительную юрисдикцию в отношении всех исков,
        связанных с посещением нашего веб-сайта, однако мы оставляем за собой
        право на предъявление иска за нарушение условий в стране вашего
        проживания или в любой другой стране. Данные Условия использования
        регулируются английским законодательством.
      </div>
      <div class="report__title">Как с нами связаться</div>
      <div class="report__description">
        Вы можете связаться с нами, отправив электронное сообщение на адрес:
        <a href="mailto:infomaster@astrazeneca.com"
          >infomaster@astrazeneca.com</a
        >
        в компанию AstraZeneca.
        <br />
        <br />
        Упоминания «AstraZeneca» и «Группа» относятся к AstraZeneca UK Limited и
        её аффилированным компаниям. В настоящих Условиях использования термин
        «Аффилированные компании» означает любые компании или хозяйственные
        субъекты, управляющие, находящиеся под управлением или имеющие
        совместное управление с компанией AstraZeneca UK Limited. В данном
        случае термин «управление» означает прямое или косвенное владение (i)
        пятьюдесятью процентами (50%) или более голосующих ценных бумаг или
        долевого участия в такой компании или субъекте; (ii) пятьюдесятью
        процентами (50%) или более долевого участия в прибылях или доходе (в
        случае с хозяйственным субъектом, исключая компанию); или (iii) в случае
        партнерских отношений – любым другим соизмеримым долевым участием
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
export default {
  metaInfo: {
    title: "Сообщить о рекламации",
  },
  name: "AllRightsReserved",
  components: { Breadcrumbs },
};
</script>

<style lang="scss" scoped>
.report {
  padding-top: 32px;
  padding-bottom: 40px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__title {
    max-width: 800px;
    margin: 24px 0;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #1f1f1f;

    &:first-child {
      margin-top: 64px;
    }

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__description {
    max-width: 800px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    & > a {
      color: #d0006f;
    }

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  .breadcrumbs {
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
}
</style>